import React, { useState, useEffect } from 'react'
import WebsiteHeader from './WebsiteHeader'
import styles from './MealPlanner.module.css'
import { useAuth0 } from '@auth0/auth0-react'
import { TOTAL_DAILY_FREE_MESSAGES } from '../constants'
import { useMediaQuery } from 'react-responsive'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

const mealTypes = ['Breakfast', 'Lunch', 'Dinner']

// Placeholder recipes for demo
const placeholderRecipes = {
  Breakfast: "Overnight Oats with Berries",
  Lunch: "Mediterranean Quinoa Bowl",
  Dinner: "Grilled Salmon with Roasted Vegetables"
}

export default function MealPlanner() {
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuth0Loading } = useAuth0()
  const [selectedMeals, setSelectedMeals] = useState([])
  const [currentWeekStart, setCurrentWeekStart] = useState(new Date())
  const [generatedMealPlan, setGeneratedMealPlan] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedRecipe, setSelectedRecipe] = useState(null)
  const [currentView, setCurrentView] = useState('selection')
  const [recipeDetails, setRecipeDetails] = useState(null)
  const [isLoadingRecipe, setIsLoadingRecipe] = useState(false)
  const [recipeError, setRecipeError] = useState(null)
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
  const [showRegenerateDialog, setShowRegenerateDialog] = useState(false)
  const [regenerateFeedback, setRegenerateFeedback] = useState('')
  const [selectedMealToRegenerate, setSelectedMealToRegenerate] = useState(null)
  const [isRegenerating, setIsRegenerating] = useState(false)
  const [isInitializing, setIsInitializing] = useState(true);
  const [isGeneratingGroceryList, setIsGeneratingGroceryList] = useState(false);
  const [groceryList, setGroceryList] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [justRegeneratedMeal, setJustRegeneratedMeal] = useState(null);
  const [isLoadingExistingPlan, setIsLoadingExistingPlan] = useState(true);

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      page: 'Meal Planner'
    });

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const diff = today.getDate() - today.getDay()
    const weekStart = new Date(today.setDate(diff))
    setCurrentWeekStart(weekStart)
    initializeSelectedMeals(weekStart)
  }, [])

  useEffect(() => {
    const fetchExistingMealPlan = async () => {
      if (!isAuth0Loading) {
        try {
          const token = await getToken();
          const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/fetch-meal-plan`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (response.ok) {
            const mealPlan = await response.json();
            setGeneratedMealPlan(mealPlan.weeklyPlan);
            setCurrentView('mealPlan');
          } else if (response.status === 404) {
            setCurrentView('selection');
          }
        } catch (error) {
          console.error('Error fetching meal plan:', error);
          setError('Failed to fetch meal plan');
          setCurrentView('selection');
        } finally {
          setIsLoadingExistingPlan(false);
          setIsInitializing(false);
        }
      }
    };

    fetchExistingMealPlan();
  }, [isAuth0Loading]);

  const initializeSelectedMeals = (weekStart) => {
    const newSelectedMeals = Array(7).fill().map(() => Array(mealTypes.length).fill(false))
    setSelectedMeals(newSelectedMeals)
  }

  const toggleMeal = (dayIndex, mealIndex) => {
    const newSelectedMeals = [...selectedMeals]
    newSelectedMeals[dayIndex][mealIndex] = !newSelectedMeals[dayIndex][mealIndex]
    setSelectedMeals(newSelectedMeals)
  }

  const totalSelectedMeals = selectedMeals.flat().filter(Boolean).length

  const changeWeek = (direction) => {
    const newWeekStart = new Date(currentWeekStart)
    newWeekStart.setDate(newWeekStart.getDate() + direction * 7)
    setCurrentWeekStart(newWeekStart)
    initializeSelectedMeals(newWeekStart)
  }

  const getDayDate = (dayIndex) => {
    const date = new Date(currentWeekStart)
    date.setDate(date.getDate() + dayIndex)
    return date
  }

  const getDayName = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  };

  const formatDate = (date) => {
    const weekday = getDayName(date);
    const monthDay = date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
    return [weekday, monthDay];
  };

  const isToday = (date) => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear()
  }

  const isPastDay = (date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
  }

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE
        }
      });
      return token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const mealSelections = selectedMeals.map((dayMeals, dayIndex) => ({
        dayIndex,
        meals: dayMeals
          .map((isSelected, mealIndex) => isSelected ? mealTypes[mealIndex] : null)
          .filter(Boolean)
      }));

      const token = await getToken()
      const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/generate-meal-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ selectedMeals: mealSelections })
      })

      if (!response.ok) {
        throw new Error('Failed to generate meal plan')
      }

      const mealPlan = await response.json()
      setGeneratedMealPlan(mealPlan.weeklyPlan)
      setCurrentView('mealPlan')

      // Add Mixpanel tracking here
      mixpanel.track('Meal Plan Generated', {
        totalMeals: totalSelectedMeals,
        selectedDays: mealSelections.length,
        success: true
      });

    } catch (error) {
      console.error('Error:', error)
      setError('Failed to generate meal plan. Please try again.')
      
      // Track failures too
      mixpanel.track('Meal Plan Generation Failed', {
        error: error.message
      });

    } finally {
      setIsLoading(false)
    }
  }

  const handleBack = () => {
    if (currentView === 'recipe') {
      setCurrentView('mealPlan');
      setSelectedRecipe(null);
    } else if (currentView === 'mealPlan') {
      setCurrentView('selection');
    } else if (currentView === 'groceryList') {
      setCurrentView('mealPlan');
    }
  };

  const handleRecipeClick = async (recipe) => {
    setIsLoadingRecipe(true);
    setRecipeError(null);
    
    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/get-recipe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          title: recipe.recipeName
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch recipe details')
      }

      const data = await response.json()
      console.log('Recipe details received:', data.recipe)
      setRecipeDetails(data.recipe)
      setSelectedRecipe(recipe)
      setCurrentView('recipe')

      // Add Mixpanel tracking for recipe view
      mixpanel.track('Recipe Viewed', {
        recipeName: recipe.recipeName,
        mealType: recipe.mealType,
        success: true
      });

    } catch (error) {
      console.error('Error:', error)
      setRecipeError('Failed to load recipe details. Please try again.')

      // Track failures
      mixpanel.track('Recipe View Failed', {
        recipeName: recipe.recipeName,
        error: error.message
      });

    } finally {
      setIsLoadingRecipe(false)
    }
  }

  const handleRegenerateRecipe = async (meal, feedback) => {
    try {
        setIsRegenerating(true);
        const token = await getToken();
        const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/regenerate-recipe-title`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                feedback,
                currentTitle: meal.recipeName,
                dayIndex: meal.dayIndex,
                mealType: meal.mealType
            })
        });

        if (!response.ok) {
            throw new Error('Failed to regenerate recipe');
        }

        const data = await response.json();
        
        // Update the meal plan with the new recipe title
        setGeneratedMealPlan(prevPlan => {
            const newPlan = { ...prevPlan };
            const dayMeals = newPlan[meal.dayIndex].meals;
            const mealIndex = dayMeals.findIndex(m => m.mealType === meal.mealType);
            dayMeals[mealIndex] = {
                ...dayMeals[mealIndex],
                recipeName: data.newTitle
            };
            return newPlan;
        });

        setShowRegenerateDialog(false);
        setRegenerateFeedback('');
        setSelectedMealToRegenerate(null);
        setJustRegeneratedMeal({ dayIndex: meal.dayIndex, mealType: meal.mealType });

        // Add Mixpanel tracking for successful regeneration
        mixpanel.track('Recipe Regenerated', {
            oldTitle: meal.recipeName,
            newTitle: data.newTitle,
            mealType: meal.mealType,
            feedback: feedback,
            success: true
        });

    } catch (error) {
        console.error('Error regenerating recipe:', error);

        // Track failures
        mixpanel.track('Recipe Regeneration Failed', {
            oldTitle: meal.recipeName,
            mealType: meal.mealType,
            feedback: feedback,
            error: error.message
        });

    } finally {
        setIsRegenerating(false);
    }
};

  const RecipeView = () => (
    <div className={styles.desktopContainer} data-view="recipe">
      <div className={styles.recipeNavigation}>
        <button onClick={handleBack} className={styles.navButton}>← Back</button>
      </div>
      <div className={styles.recipeContent}>
        {recipeError ? (
          <div className={styles.error}>{recipeError}</div>
        ) : recipeDetails ? (
          <div className={styles.recipeLayout}>
            <div className={styles.recipeInfo}>
              <h2 className={styles.recipeTitle}>{selectedRecipe.recipeName}</h2>
              <section className={styles.recipeSection}>
                <h3 className={styles.sectionTitle}>Ingredients</h3>
                <ul className={styles.ingredientsList}>
                  {recipeDetails.ingredients.map((ingredient, index) => (
                    <li key={index}>{ingredient}</li>
                  ))}
                </ul>
              </section>
              <section className={styles.recipeSection}>
                <h3 className={styles.sectionTitle}>Instructions</h3>
                <ol className={styles.instructionsList}>
                  {recipeDetails.instructions.map((step, index) => (
                    <li key={index}>
                      {step.replace(/^\d+\.\s*/, '')}
                    </li>
                  ))}
                </ol>
              </section>
            </div>
            <div className={styles.recipeImageContainer}>
              {recipeDetails.imageUrl ? (
                <img 
                  src={recipeDetails.imageUrl} 
                  alt={recipeDetails.title}
                  className={styles.recipeImage}
                />
              ) : (
                <span>Recipe image coming soon</span>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.error}>No recipe details available</div>
        )}
      </div>
    </div>
  )

  const handleGroceryListClick = async () => {
    setIsGeneratingGroceryList(true);
    try {
      const recipeTitles = generatedMealPlan.flatMap(day => 
        day.meals.map(meal => meal.recipeName)
      );
      console.log('Sending recipe titles:', recipeTitles);

      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_BACK_END_URL}/api/generate-grocery-list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ recipeTitles })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.details || 'Failed to generate grocery list');
      }

      console.log('Received grocery list:', data.ingredients);
      setGroceryList(data.ingredients);
      setCurrentView('groceryList');

      // Add Mixpanel tracking for successful generation
      mixpanel.track('Grocery List Generated', {
        numberOfIngredients: data.ingredients.length,
        success: true
      });

    } catch (error) {
      console.error('Error generating grocery list:', error);
      setError('Failed to generate grocery list. Please try again.');

      // Track failures
      mixpanel.track('Grocery List Generation Failed', {
        error: error.message
      });

    } finally {
      setIsGeneratingGroceryList(false);
    }
  };

  const GroceryListView = () => (
    <div className={styles.container}>
      <div className={styles.recipeNavigation}>
        <button onClick={handleBack} className={styles.navButton}>&larr; Back</button>
        <h2 className={styles.title}>Grocery List</h2>
      </div>
      <div className={styles.groceryListContent}>
        {groceryList.length > 0 ? (
          <ul className={styles.ingredientsList}>
            {groceryList.map((ingredient, index) => (
              <li key={index}>{ingredient}</li>
            ))}
          </ul>
        ) : (
          <div className={styles.loadingMessage}>
            Generating your grocery list...
          </div>
        )}
      </div>
    </div>
  );

  const MealPlanView = () => (
    <div className={styles.container}>
      <div className={styles.recipeNavigation}>
        <button onClick={handleBack} className={styles.navButton}>&larr; Back</button>
        <h2 className={styles.title}>Meal Plan</h2>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}></th>
              {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                const date = getDayDate(dayIndex);
                const [weekday, monthDay] = formatDate(date);
                return (
                  <th 
                    key={dayIndex} 
                    className={`${styles.th} ${isToday(date) ? styles.today : ''}`}
                  >
                    {weekday}
                    <span className={styles.dateText}>{monthDay}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {mealTypes.map((mealType, mealIndex) => (
              <tr key={mealType}>
                <td className={styles.td}>{mealType}</td>
                {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                  const date = getDayDate(dayIndex);
                  const isDisabled = isPastDay(date);
                  return (
                    <td key={dayIndex} className={styles.td}>
                      <button
                        className={`${styles.mealButton} ${
                          selectedMeals[dayIndex] && selectedMeals[dayIndex][mealIndex]
                            ? styles.mealButtonSelected
                            : styles.mealButtonUnselected
                        } ${isDisabled ? styles.mealButtonDisabledDistinct : ''}`}
                        onClick={() => !isDisabled && toggleMeal(dayIndex, mealIndex)}
                        disabled={isDisabled}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.groceryListContainer}>
        <button 
          className={styles.groceryListButton}
          onClick={handleGroceryListClick}
          disabled={isGeneratingGroceryList}
        >
          Generate Grocery List
        </button>
      </div>

      {isGeneratingGroceryList && (
        <div className={styles.loadingOverlay}>
          <div className={styles.toastNotification}>
            <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
            <div>Generating...</div>
          </div>
        </div>
      )}

      {showRegenerateDialog && (
        <div className={styles.loadingOverlay}>
          <div className={styles.regenerateDialog}>
            <h3>{selectedMealToRegenerate?.recipeName}</h3>
            <p>What would you like to change about this recipe?</p>
            <textarea
              value={regenerateFeedback}
              onChange={(e) => setRegenerateFeedback(e.target.value)}
              placeholder="e.g., Make it vegetarian, use different ingredients, etc."
              className={styles.regenerateTextarea}
            />
            <div className={styles.dialogButtons}>
              <button
                onClick={() => {
                  setShowRegenerateDialog(false);
                  setRegenerateFeedback('');
                  setSelectedMealToRegenerate(null);
                }}
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button
                onClick={() => handleRegenerateRecipe(selectedMealToRegenerate, regenerateFeedback)}
                className={styles.submitButton}
                disabled={!regenerateFeedback.trim() || isRegenerating}
              >
                {isRegenerating ? 'Regenerating...' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const renderMobileView = () => {
    if (currentView === 'selection') {
      return (
        <div className={styles.mobileContainer} data-view="selection">
          <div className={styles.mobileContent}>
            <h1 className={styles.mobileTitle}>
              Which meals would you like to cook this week?
            </h1>
            <div className={styles.mobilePreferencesWrapper}>
              <Link to="/preferences" className={styles.preferencesLink}>
                <button className={styles.mobilePreferencesButton}>
                  <img src="/preferences_icon.svg" alt="Preferences" className={styles.preferencesIcon} />
                  Preferences
                </button>
              </Link>
            </div>
            {/*
            <div className={styles.mobileWeekNavigation}>
              <button 
                onClick={() => changeWeek(-1)} 
                className={styles.mobileNavButton}
              >
                ← Previous
              </button>
              <button 
                onClick={() => changeWeek(1)} 
                className={styles.mobileNavButton}
              >
                Next →
              </button>
            </div>
            */}
            {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
              const date = getDayDate(dayIndex);
              if (isPastDay(date)) {
                return null;
              }
              
              const [weekday, monthDay] = formatDate(date);
              return (
                <div key={dayIndex} className={styles.dayCard}>
                  <div className={styles.dayHeader}>
                    <span className={styles.dayName}>
                      {weekday} <span className={styles.dayDate}>{monthDay}</span>
                    </span>
                  </div>
                  <div className={styles.mealOptions}>
                    {mealTypes.map((mealType, mealIndex) => (
                      <button
                        key={mealType}
                        className={`${styles.mobileMealButton} ${
                          selectedMeals[dayIndex] && selectedMeals[dayIndex][mealIndex]
                            ? styles.mobileMealButtonSelected
                            : ''
                        }`}
                        onClick={() => toggleMeal(dayIndex, mealIndex)}
                        aria-label={`Select ${mealType} for ${weekday}`}
                      >
                        {mealType}
                      </button>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          
          <div className={styles.submitSection}>
            <div className={styles.mealCount}>
              <span><span className={styles.countNumber}>{totalSelectedMeals}</span> meals</span>
            </div>
            <button 
              className={styles.submitButton}
              onClick={handleSubmit}
              disabled={totalSelectedMeals === 0}
            >
              Submit
            </button>
          </div>

          {isLoading && (
            <div className={styles.loadingOverlay}>
              <div className={styles.toastNotification}>
                <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
                <div>Generating...</div>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (currentView === 'mealPlan') {
      return (
        <div className={styles.mobileContainer} data-view="mealPlan">
          <div className={styles.mobileContent}>
            <div className={styles.recipeNavigation}>
              <h2 className={styles.title}>Meal Plan</h2>
            </div>
            
            {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
              const date = getDayDate(dayIndex);
              if (isPastDay(date)) {
                return null;
              }
              
              const [weekday, monthDay] = formatDate(date);
              const dayMeals = generatedMealPlan[dayIndex]?.meals || [];
              
              return (
                <div key={dayIndex} className={styles.dayCard}>
                  <div className={styles.dayHeader}>
                    <span className={styles.dayName}>
                      {weekday} <span className={styles.dayDate}>{monthDay}</span>
                    </span>
                  </div>
                  <div className={styles.mealsList}>
                    {dayMeals.length > 0 ? (
                      dayMeals.map((meal, mealIndex) => (
                        <div
                            key={mealIndex}
                            className={styles.recipeButton}
                        >
                            <button
                                className={styles.regenerateButton}
                                onClick={() => {
                                    setShowRegenerateDialog(true);
                                    setSelectedMealToRegenerate({
                                        ...meal,
                                        dayIndex,
                                        mealType: meal.mealType
                                    });
                                }}
                            >
                                <img src="/refresh_icon.svg" alt="Regenerate" className={styles.regenerateIcon} />
                            </button>
                            <div 
                                className={styles.recipeContent}
                                onClick={() => handleRecipeClick(meal)}
                            >
                                <span className={styles.mealType}>{meal.mealType}</span>
                                <span className={styles.recipeName}>{meal.recipeName}</span>
                            </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.noMealsMessage}>
                        No meals selected for this day
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            
            <div className={styles.groceryListContainer}>
              <button 
                className={styles.groceryListButton}
                onClick={handleGroceryListClick}
                disabled={isGeneratingGroceryList}
              >
                Generate Grocery List
              </button>
            </div>
          </div>

          {showRegenerateDialog && (
            <div className={styles.dialogOverlay}>
              <div className={styles.dialog}>
                <h2>{selectedMealToRegenerate?.recipeName}</h2>
                <p>What would you like to change about this recipe?</p>
                <textarea
                  value={regenerateFeedback}
                  onChange={(e) => setRegenerateFeedback(e.target.value)}
                  placeholder="e.g., Make it vegetarian, use different vegetables, etc."
                  className={styles.feedbackInput}
                />
                <div className={styles.dialogButtons}>
                  <button
                    onClick={() => {
                      setShowRegenerateDialog(false);
                      setRegenerateFeedback('');
                    }}
                    className={styles.cancelButton}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleRegenerateRecipe(selectedMealToRegenerate, regenerateFeedback)}
                    className={styles.submitButton}
                    disabled={!regenerateFeedback.trim() || isRegenerating}
                  >
                    {isRegenerating ? 'Regenerating...' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          )}

          {isGeneratingGroceryList && (
            <div className={styles.loadingOverlay}>
              <div className={styles.toastNotification}>
                <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
                <div>Generating...</div>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (currentView === 'recipe') {
      return (
        <div className={styles.mobileContainer} data-view="recipe">
          <div className={styles.mobileContent}>
            <div className={styles.recipeNavigation}>
              <button onClick={handleBack} className={styles.navButton}>← Back</button>
              <h2 className={styles.title}>{selectedRecipe.recipeName}</h2>
            </div>
            <div className={styles.recipeContent}>
              {recipeError ? (
                <div className={styles.error}>{recipeError}</div>
              ) : recipeDetails ? (
                <div className={styles.recipeLayout}>
                  <div className={styles.recipeImageContainer}>
                    {recipeDetails.imageUrl ? (
                      <img 
                        src={recipeDetails.imageUrl} 
                        alt={recipeDetails.title}
                        className={styles.recipeImage}
                      />
                    ) : (
                      <span className={styles.imagePlaceholder}>Recipe image coming soon</span>
                    )}
                  </div>
                  <section className={styles.recipeSection}>
                    <h3 className={styles.sectionTitle}>Ingredients</h3>
                    <ul className={styles.ingredientsList}>
                      {recipeDetails.ingredients.map((ingredient, index) => (
                        <li key={index}>{ingredient}</li>
                      ))}
                    </ul>
                  </section>
                  <section className={styles.recipeSection}>
                    <h3 className={styles.sectionTitle}>Instructions</h3>
                    <ol className={styles.instructionsList}>
                      {recipeDetails.instructions.map((step, index) => (
                        <li key={index}>{step.replace(/^\d+\.\s*/, '')}</li>
                      ))}
                    </ol>
                  </section>
                </div>
              ) : (
                <div className={styles.loadingMessage}>Loading recipe...</div>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (currentView === 'groceryList') {
      return (
        <div className={styles.mobileContainer}>
          <div className={styles.mobileContent}>
            <div className={styles.recipeNavigation}>
              <button onClick={handleBack} className={styles.navButton}>← Back</button>
              <h2 className={styles.title}>Grocery List</h2>
            </div>
            <div className={styles.groceryListContent}>
              {groceryList.length > 0 ? (
                <ul className={styles.ingredientsList}>
                  {groceryList.map((ingredient, index) => (
                    <li key={index}>{ingredient}</li>
                  ))}
                </ul>
              ) : (
                <div className={styles.loadingMessage}>
                  Generating your grocery list...
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderDesktopView = () => {
    if (currentView === 'selection') {
      return (
        <div className={styles.desktopContainer}>
          <h1 className={styles.title}>
            Which meals would you like to cook this week?
          </h1>
          {/*
          <div className={styles.weekNavigation}>
            <button onClick={() => changeWeek(-1)} className={styles.navButton}>← Previous</button>
            <button onClick={() => changeWeek(1)} className={styles.navButton}>Next →</button>
          </div>
          */}
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.th}></th>
                  {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                    const date = getDayDate(dayIndex);
                    const [weekday, monthDay] = formatDate(date);
                    return (
                      <th 
                        key={dayIndex} 
                        className={`${styles.th} ${isToday(date) ? styles.today : ''}`}
                      >
                        {weekday}
                        <span className={styles.dateText}>{monthDay}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {mealTypes.map((mealType, mealIndex) => (
                  <tr key={mealType}>
                    <td className={styles.td}>{mealType}</td>
                    {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                      const date = getDayDate(dayIndex);
                      const isDisabled = isPastDay(date);
                      return (
                        <td key={dayIndex} className={styles.td}>
                          <button
                            className={`${styles.mealButton} ${
                              selectedMeals[dayIndex] && selectedMeals[dayIndex][mealIndex]
                                ? styles.mealButtonSelected
                                : styles.mealButtonUnselected
                            } ${isDisabled ? styles.mealButtonDisabledDistinct : ''}`}
                            onClick={() => !isDisabled && toggleMeal(dayIndex, mealIndex)}
                            disabled={isDisabled}
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.footer}>
            <Link to="/preferences" className={styles.preferencesLink}>
              <button className={styles.preferencesButton}>
                <img src="/preferences_icon.svg" alt="Preferences" className={styles.preferencesIcon} />
                Preferences
              </button>
            </Link>
            <div className={styles.rightSection}>
              <div className={styles.mealCounter}>
                <span className={styles.mealCount}>{totalSelectedMeals}</span>
                <span className={styles.mealLabel}>meals</span>
              </div>
              <button 
                className={styles.submitButton}
                onClick={handleSubmit}
                disabled={totalSelectedMeals === 0}
              >
                Submit
              </button>
            </div>
          </div>

          {isLoading && (
            <div className={styles.loadingOverlay}>
              <div className={styles.toastNotification}>
                <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
                <div>Generating...</div>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (currentView === 'mealPlan') {
      return (
        <div className={styles.desktopContainer} data-view="mealPlan">
          <div className={styles.recipeNavigation}>
            <h2 className={styles.title}>Meal Plan</h2>
          </div>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.th}></th>
                  {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                    const date = getDayDate(dayIndex);
                    const [weekday, monthDay] = formatDate(date);
                    return (
                      <th 
                        key={dayIndex} 
                        className={`${styles.th} ${isToday(date) ? styles.today : ''}`}
                      >
                        {weekday}
                        <span className={styles.dateText}>{monthDay}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.td}></td>
                  {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
                    <td key={dayIndex} className={styles.recipeTd}>
                      {generatedMealPlan[dayIndex]?.meals.map((meal, index) => (
                        <div
                          key={index}
                          className={`${styles.recipeTile} ${styles.clickable} ${
                            justRegeneratedMeal?.mealType === meal.mealType && 
                            justRegeneratedMeal?.dayIndex === dayIndex 
                              ? styles.justRegenerated 
                              : ''
                          }`}
                          onClick={() => handleRecipeClick(meal)}
                        >
                          <div className={styles.recipeTileContent}>
                            <div className={styles.mealType}>{meal.mealType}</div>
                            <div className={styles.recipeName} title={meal.recipeName}>
                              {meal.recipeName}
                            </div>
                          </div>
                          <button
                            className={styles.regenerateButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRegenerateDialog(true);
                              setSelectedMealToRegenerate({
                                ...meal,
                                dayIndex,
                                mealType: meal.mealType
                              });
                            }}
                          >
                            <img src="/refresh_icon.svg" alt="Regenerate" className={styles.regenerateIcon} />
                          </button>
                        </div>
                      ))}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.groceryListContainer}>
            <button 
              className={styles.groceryListButton}
              onClick={handleGroceryListClick}
              disabled={isGeneratingGroceryList}
            >
              Generate Grocery List
            </button>
          </div>

          {isGeneratingGroceryList && (
            <div className={styles.loadingOverlay}>
              <div className={styles.toastNotification}>
                <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
                <div>Generating...</div>
              </div>
            </div>
          )}

          {showRegenerateDialog && (
            <div className={styles.loadingOverlay}>
              <div className={styles.regenerateDialog}>
                <h3>{selectedMealToRegenerate?.recipeName}</h3>
                <p>What would you like to change about this recipe?</p>
                <textarea
                  value={regenerateFeedback}
                  onChange={(e) => setRegenerateFeedback(e.target.value)}
                  placeholder="e.g., Make it vegetarian, use different ingredients, etc."
                  className={styles.regenerateTextarea}
                />
                <div className={styles.dialogButtons}>
                  <button
                    onClick={() => {
                      setShowRegenerateDialog(false);
                      setRegenerateFeedback('');
                      setSelectedMealToRegenerate(null);
                    }}
                    className={styles.cancelButton}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleRegenerateRecipe(selectedMealToRegenerate, regenerateFeedback)}
                    className={styles.submitButton}
                    disabled={!regenerateFeedback.trim() || isRegenerating}
                  >
                    {isRegenerating ? 'Regenerating...' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (currentView === 'recipe') {
      return <RecipeView />;
    }

    if (currentView === 'groceryList') {
      return (
        <div className={styles.desktopContainer}>
          <div className={styles.recipeNavigation}>
            <button onClick={handleBack} className={styles.navButton}>← Back</button>
            <h2 className={styles.title}>Grocery List</h2>
          </div>
          <div className={styles.groceryListContent}>
            {groceryList.length > 0 ? (
              <ul className={styles.ingredientsList}>
                {groceryList.map((ingredient, index) => (
                  <li key={index}>{ingredient}</li>
                ))}
              </ul>
            ) : (
              <div className={styles.loadingMessage}>
                Generating your grocery list...
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <WebsiteHeader getToken={getToken} remainingDailyFreeMessages={TOTAL_DAILY_FREE_MESSAGES} />
      {(isInitializing || isAuth0Loading || isLoadingExistingPlan) ? (
        <div className={styles.loadingOverlay}>
          <div className={styles.toastNotification}>
            <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
            <div>Loading...</div>
          </div>
        </div>
      ) : isMobile ? (
        renderMobileView()
      ) : (
        renderDesktopView()
      )}
      {isLoadingRecipe && (
        <div className={styles.loadingOverlay}>
          <div className={styles.toastNotification}>
            <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
            <div>Loading recipe...</div>
          </div>
        </div>
      )}
    </div>
  )
}

