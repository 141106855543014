import React, { useState, useEffect } from 'react';
import WebsiteHeader from './WebsiteHeader';
import styles from './Preferences.module.css';
import { useAuth0 } from '@auth0/auth0-react';
import { TOTAL_DAILY_FREE_MESSAGES } from '../constants';
import mixpanel from 'mixpanel-browser';

const Preferences = () => {
    const { getAccessTokenSilently, isLoading: isAuth0Loading } = useAuth0();
    const [isInitializing, setIsInitializing] = useState(true);
    const [preferences, setPreferences] = useState('');
    const apiUrl = process.env.REACT_APP_BACK_END_URL;
    const auth0ApiAudience = process.env.REACT_APP_AUTH0_API_AUDIENCE;
    const [showSaved, setShowSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const getToken = async () => {
        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: auth0ApiAudience
                }
            });
            return token;
        } catch (error) {
            console.error('Error fetching token:', error);
            throw error;
        }
    };

    useEffect(() => {
        mixpanel.track('Page Viewed', {
            page: 'Preferences'
        });

        const fetchPreferences = async () => {
            if (!isAuth0Loading) {
                try {
                    const token = await getToken();
                    const response = await fetch(`${apiUrl}/api/get-preferences`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    const data = await response.json();
                    setPreferences(data.preferences);
                    setIsInitializing(false);
                } catch (error) {
                    console.error('Error fetching preferences:', error);
                    setIsInitializing(false);
                }
            }
        };

        fetchPreferences();
    }, [isAuth0Loading]);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const token = await getToken();
            const response = await fetch(`${apiUrl}/api/update-preferences`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ preferences })
            });

            if (response.ok) {
                setIsSaving(false);
                setShowSaved(true);
                setTimeout(() => setShowSaved(false), 2000);

                // Track successful preference save
                mixpanel.track('Preferences Saved', {
                    success: true
                });
            }
        } catch (error) {
            console.error('Error updating preferences:', error);
            setIsSaving(false);

            // Track failed preference save
            mixpanel.track('Preferences Save Failed', {
                error: error.message
            });
        }
    };

    return (
        <>
            {(isInitializing || isAuth0Loading) ? (
                <div className={styles.loadingOverlay}>
                    <div className={styles.toastNotification}>
                        <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
                        <div>Loading...</div>
                    </div>
                </div>
            ) : (
                <>
                    <WebsiteHeader 
                        getToken={getToken} 
                        remainingDailyFreeMessages={TOTAL_DAILY_FREE_MESSAGES} 
                    />
                    <div className={styles.container}>
                        <h1 className={styles.title}>Preferences</h1>
                        <textarea
                            className={styles.preferencesInput}
                            value={preferences}
                            onChange={(e) => setPreferences(e.target.value)}
                            placeholder="Enter your dietary and cooking preferences here..."
                            rows="6"
                        />
                        <div className={styles.saveContainer}>
                            <button 
                                className={styles.saveButton}
                                onClick={handleSave}
                            >
                                Save
                            </button>
                            {isSaving && (
                                <img 
                                    src="/loading_icon.svg" 
                                    alt="Saving..." 
                                    className={styles.savingIcon}
                                />
                            )}
                            {!isSaving && showSaved && (
                                <span className={styles.savedMessage}>Saved!</span>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Preferences; 