import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ChatApp from './components/ChatApp';
import OrderPreview from './components/OrderPreview';
import MyCookbook from './components/MyCookbook';
import Ingredients from './components/Ingredients';
import MealPlanner from './components/MealPlanner';
import Preferences from './components/Preferences';
import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from "mixpanel-browser";

function App() {

  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      mixpanel.identify(user.sub);

      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
      });

      mixpanel.track('Logged In');

    }
  }, [isAuthenticated, user]);


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ChatApp />} />
        <Route path="/order-preview" element={<OrderPreview />} />
        <Route path="/my-cookbook" element={<MyCookbook />} />
        <Route path="/ingredients" element={<Ingredients />} />
        <Route path="/meal-planner" element={<MealPlanner />} />
        <Route path="/preferences" element={<Preferences />} />
      </Routes>
    </div>
  );
}

export default App;