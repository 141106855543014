import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import styles from './WebsiteHeader.module.css';
import { TOTAL_DAILY_FREE_MESSAGES } from '../constants';
import mixpanel from 'mixpanel-browser';

const WebsiteHeader = ({ getToken, remainingDailyFreeMessages }) => {
    const { loginWithRedirect, logout, isAuthenticated, user, isLoading } = useAuth0();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const apiUrl = process.env.REACT_APP_BACK_END_URL;
    const [remainingMessagesViaBackend, setRemainingMessagesViaBackend] = useState(0);
    const totalDailyFreeMessages = TOTAL_DAILY_FREE_MESSAGES;

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const location = useLocation();

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    }

    const burgerMenuStyles = {
        bmBurgerButton: {
            position: 'absolute',
            width: '30px',
            height: '25px',
            right: '20px',
            top: '24px',
        },
        bmBurgerBars: {
            background: '#373a47',
        },
        bmCrossButton: {
            height: '24px',
            width: '24px',
        },
        bmCross: {
            background: 'black',
        },
        bmMenuWrap: {
            top: '0',
        },
        bmMenu: {
            background: '#A4C5C5',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
        },
        bmItemList: {
            color: '#b8b7ad',
        },
        bmItem: {
            display: 'block',
            color: 'black',
            padding: '10px 0',
            textDecoration: 'none',
            alignItems: 'center',
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)',
            top: '0',
            left: '0',
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            setTimeout(() => {
                if (!event.target.closest('.profileButton') && !event.target.closest('.dropdownMenu')) {
                    setIsDropdownVisible(false);
                }
            }, 100);
        };

        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            if (isAuthenticated && user) {
                console.log('The user is authenticated, so I am proceeding to check the subscription status.');
                try {
                    const token = await getToken();
                    const response = await fetch(`${apiUrl}/api/check-subscription-status?userId=${user.sub}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    });
                    const data = await response.json();
                    setIsSubscribed(data.isSubscribed);
                    console.log('Is the user subscribed? ' + data.isSubscribed);
                } catch (error) {
                    console.error('Error checking subscription status:', error);
                }
            }
        };

        const fetchMessageCount = async () => {
            if (isAuthenticated && user) {
                try {
                    const token = await getToken();
                    const response = await fetch(`${apiUrl}/api/getDailyMessageCount`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    setRemainingMessagesViaBackend(totalDailyFreeMessages - data.messageCount);
                } catch (error) {
                    console.error('Error fetching daily message count:', error);
                }
            }
        };

        checkSubscriptionStatus();
        fetchMessageCount();
    }, [isAuthenticated, user]);

    const handleManageSubscription = async () => {
        try {

            const token = await getToken();
            console.log('I was able to get the token and it is ' + token);

            const response = await fetch(`${apiUrl}/api/create-customer-portal-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,  // Include Auth0 token if using Auth0
                }
            });

            const data = await response.json();
            window.location.href = data.url;  // Redirect to Stripe Customer Portal
        } catch (error) {
            console.error('Error redirecting to Stripe customer portal:', error);
        }
    };

    const handleLogout = () => {
        //console.log('You have pressed the Log Out button');

        mixpanel.track('Logged Out');

        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    };

    const getMessageCountClass = () => {

        let counter = null;

        if (isAuthenticated) {
            counter = remainingMessagesViaBackend;
        } else {
            counter = remainingDailyFreeMessages;
        }

        if (counter > 5) {
            return styles.greenBackground;
        } else if (counter > 2) {
            return styles.orangeBackground;
        } else {
            return styles.redBackground;
        }
    };

    return (
        <div className={styles.websiteHeader}>
            <img src="/logo_transparent.png" alt="Alfalfa Logo" className={styles.logoImage} />
            <div className={styles.websiteName}>Alfalfa</div>

            {isAuthenticated ? (
                <>
                    {/* Put code here for user who is authenticated*/}
                    {isSubscribed ? (
                        // Put code here for user who is authenticated and subscribed
                        <span className={styles.plusBadge}>Plus</span>
                    ) : (
                        <>
                            {/* Put code here for user who is authenticated but not subscribed*/}
                            <Link to = "/order-preview">
                                    <button className={styles.subscribeButton}>{isMobile ? "Subscribe" : "Subscribe to Plus"}</button>
                            </Link>

                            {isMobile ? (
                                <div className={`${styles.messageCount} ${getMessageCountClass()}`} style={{ margin: '0 0 0 10px' }}>
                                    <span><strong>{remainingMessagesViaBackend}</strong>/{totalDailyFreeMessages}</span>
                                </div>
                            ) : (
                                <div className={`${styles.messageCount} ${getMessageCountClass()}`}>
                                    <span><strong>{remainingMessagesViaBackend} free messages</strong> remaining today.</span>
                                </div>
                            )}
                        </>
                    )}

                    {/*Put code here for user who is authenticated, regardless of whether he or she is subscribed*/}
                    {isMobile ? (
                        <Menu right styles={burgerMenuStyles}>
                            <span className={styles.userName}>{user.name}</span>
                            <Link to="/meal-planner" className={styles.menuItem}>
                                <div className={styles.menuItemContent}>
                                    <img src="/calendar_icon.svg" alt="Meal Planner Icon" className={styles.bmIcon} />
                                    Meal Planner
                                </div>
                            </Link>
                            <Link to="/my-cookbook" className={styles.menuItem}>
                                <div className={styles.menuItemContent}>
                                    <img src="/cookbook_icon_3.svg" alt="Cookbook Icon" className={styles.bmIcon} />
                                    Cookbook
                                </div>
                            </Link>
                            <Link to="/ingredients" className={styles.menuItem}>
                                <div className={styles.menuItemContent}>
                                    <img src="/ingredients_icon.svg" alt="Ingredients Icon" className={styles.bmIcon} />
                                    Ingredients
                                </div>
                            </Link>
                            <a id="chat" className={styles.menuItem} href="/">
                                <div className={styles.menuItemContent}>
                                    <img src="/chat_icon.svg" alt="Chat Icon" className={styles.bmIcon} />
                                    Chat
                                </div>
                            </a>
                            {isSubscribed && (
                                <a id="manage-subscription" className={styles.menuItem} href="#" onClick={handleManageSubscription}>
                                    <div className={styles.menuItemContent}>
                                        <img src="/settings_icon.svg" alt="settings" className={styles.bmIcon} />
                                        Manage Subscription
                                    </div>
                                </a>
                            )}
                            <a id="logout" className={`${styles.menuItem} ${styles.logoutButton}`} href="#" onClick={handleLogout}>
                                <div className={styles.menuItemContent}>
                                    <img src="/logout_icon_2.svg" alt="logout" className={styles.bmIcon} />
                                    Log Out
                                </div>
                            </a>
                        </Menu>
                    ) : (
                            <div className={styles.desktopNavigation} style={isSubscribed ? { marginLeft: 'auto' } : {}}>
                                <Link to="/meal-planner" className={styles.mealPlannerLink}>
                                    <button className={`${styles.mealPlannerButton} ${location.pathname === '/meal-planner' ? styles.active : ''}`}>
                                        <img src="/calendar_icon.svg" alt="Meal Planner Icon" className={styles.mealPlannerIcon} />
                                        Meal Planner
                                    </button>
                                </Link>
                                <Link to="/my-cookbook" className={styles.myCookbookLink}>
                                    <button className={`${styles.myCookbookButton} ${location.pathname === '/my-cookbook' ? styles.active : ''}`}>
                                        <img src="/cookbook_icon_3.svg" alt="Cookbook Icon" className={styles.cookbookIcon} />
                                        Cookbook
                                    </button>
                                </Link>
                                <Link to="/" className={styles.chatLink}>
                                    <button className={`${styles.chatButton} ${location.pathname === '/' ? styles.active : ''}`}>
                                        <img src="/chat_icon.svg" alt="Chat Icon" className={styles.chatIcon} />
                                        Chat
                                    </button>
                                </Link>

                                <div className={styles.profileButton} onClick={toggleDropdown}>
                                    <img src="/user_icon.svg" alt="Profile" className={styles.profileIcon} />
                                    <span>{user.name}</span>
                                </div>

                                {isDropdownVisible && (
                                    <div className={styles.dropdownMenu}>
                                        {isSubscribed && (
                                            <button className={styles.dropdownItem} onClick={handleManageSubscription}>
                                                <img src="/settings_icon.svg" alt="settings" className={styles.dropdownIcon} />
                                                Manage Subscription
                                            </button>
                                        )}
                                        <Link to="/preferences" className={styles.dropdownLink}>
                                            <button className={`${styles.dropdownItem} ${location.pathname === '/preferences' ? styles.active : ''}`}>
                                                <img src="/preferences_icon.svg" alt="preferences" className={styles.dropdownIcon} />
                                                Preferences
                                            </button>
                                        </Link>
                                        <button className={styles.dropdownItem} onClick={handleLogout}>
                                            <img src="/logout_icon_2.svg" alt="logout" className={styles.dropdownIcon} />
                                            Log Out
                                        </button>
                                    </div>
                                )}
                            </div>
                    )}
                </>
            ) : (
                // Put code here for user who is unauthenticated
                <>
                    {isMobile ? (
                        <div className={`${styles.messageCount} ${getMessageCountClass()}`}>
                                <span><strong>{remainingDailyFreeMessages}</strong>/{totalDailyFreeMessages}</span>
                        </div>
                    ) : (
                        <div className={`${styles.messageCount} ${getMessageCountClass()}`}>
                            <span><strong>{remainingDailyFreeMessages} free messages</strong> remaining today.</span>
                        </div>
                    )}
                    <button className={styles.authButtons} onClick={() => loginWithRedirect()}>Log In</button>
                </>
            )}

        </div>
    );
};

export default WebsiteHeader;