import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import fetchChatHistory from '../services/fetchChatHistory';
import sendMessage from '../services/sendMessage';
import WebsiteHeader  from './WebsiteHeader';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import styles from './ChatApp.module.css';
import { TOTAL_DAILY_FREE_MESSAGES } from '../constants';
import mixpanel from 'mixpanel-browser';

const ChatApp = () => {

  const { isAuthenticated, user, getAccessTokenSilently, isLoading: isAuth0Loading } = useAuth0();
  const [messages, setMessages] = useState([]);
  //const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showFirstMessageToast, setShowFirstMessageToast] = useState(false);
  const apiUrl = process.env.REACT_APP_BACK_END_URL;
  const auth0ApiAudience = process.env.REACT_APP_AUTH0_API_AUDIENCE;
  const totalDailyFreeMessages = TOTAL_DAILY_FREE_MESSAGES;
  const [isInitializing, setIsInitializing] = useState(true);

  const getTodaysDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getMessageCount = () => {
    const storedData = localStorage.getItem('messageCount');
    if (!storedData) return { date: getTodaysDate(), count: 0 };

    const parsedData = JSON.parse(storedData);
    if (parsedData.date !== getTodaysDate()) {
      return { date: getTodaysDate(), count: 0 };
    }
    return parsedData;
  };

  const incrementMessageCount = () => {
    const { date, count } = getMessageCount();
    const updatedData = { date, count: count + 1 };
    localStorage.setItem('messageCount', JSON.stringify(updatedData));
  };

  const remainingDailyFreeMessages = totalDailyFreeMessages - getMessageCount().count;

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0ApiAudience
        }
      });
      return token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  const loadMessages = async () => {
    if (isAuthenticated) {
      try {
        const token = await getToken();
        const numberOfLoadedMessages = messages.length;
        const { fetchedMessages, fetchedHasSubmitted } = await fetchChatHistory(token, apiUrl, numberOfLoadedMessages);

        setHasSubmitted(fetchedHasSubmitted);

        console.log("Fetched messages:", fetchedMessages);

        if (messages.length > 0) {
          setMessages((prevMessages) => [...fetchedMessages, ...prevMessages]);
          console.log("Updated messages (appended):", [...fetchedMessages, ...messages]);
        } else {
          setMessages(fetchedMessages);
          console.log("Updated messages (first load):", fetchedMessages);
        }

      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    }
  };

  useEffect(() => {
    mixpanel.track('Page Viewed', {
      page: 'Chat'
    });
  }, []);

  useEffect(() => {
    const initializeChat = async () => {
      if (!isAuth0Loading) {
        if (isAuthenticated) {
          try {
            const token = await getToken();
            const { fetchedMessages, fetchedHasSubmitted } = await fetchChatHistory(token, apiUrl, 0);
            setHasSubmitted(fetchedHasSubmitted);
            setMessages(fetchedMessages);
          } catch (error) {
            console.error('Error loading chat history:', error);
          }
        }
        setIsInitializing(false);
      }
    };

    initializeChat();
  }, [isAuthenticated, isAuth0Loading]);

  const handleSend = async (input) => {
    
    let token = null;
    if (isAuthenticated) {
      try {
        token = await getToken();
      } catch (error) {
        console.error('Error fetching token for sending message:', error);
        return;
      }
    }
    
    if (!isAuthenticated) {
      const { count } = getMessageCount();
      if (count >= totalDailyFreeMessages) {
        const botMessage = {
          sender: 'bot',
          text: "Unfortunately, you've reached your daily limit of **10 free messages**. But there's good news! You can upgrade to **Alfalfa Plus**, which offers unlimited daily messages, for only **$5 per month**. To get started, log in or create an account. Happy cooking! 👨🏽‍🍳"
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        return;
      }

      if (count === 0) {
        setShowFirstMessageToast(true);
        setTimeout(() => setShowFirstMessageToast(false), 10000);
      }

      incrementMessageCount();
    }

    mixpanel.track('Message Sent', {
      content: input,
      isAuthenticated: isAuthenticated
    });

    await sendMessage({
      input,
      messages,
      setMessages,
      setIsTyping,
      setHasSubmitted,
      token,
      isAuthenticated,
      apiUrl,
      user
    });
  };

  /*const handlePromptClick = (promptButtonMessage) => {
    handleSend(promptButtonMessage);
  };*/

  const getDailyMessageCount = async () => {

    let token = null;
    try {
      token = await getToken();
    } catch (error) {
      console.error('Error fetching token', error);
      return 0;
    }

    try {
      const response = await fetch(`${apiUrl}/api/getDailyMessageCount`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data.messageCount;
    } catch (error) {
      console.error('Error fetching daily message count:', error);
      return 0;
    };
  };

  useEffect(() => {
    if (isAuthenticated) {
      const fetchMessageCount = async () => {
        const messageCount = await getDailyMessageCount();
        console.log(`You have sent ${messageCount} messages today.`);
      };

      fetchMessageCount();
    }
  }, [messages.length]);

  return (
    <>
      {(isInitializing || isAuth0Loading) ? (
        <div className={styles.loadingOverlay}>
          <div className={styles.toastNotification}>
            <img src="/loading_icon.svg" alt="Loading" className={styles.loadingIcon} />
            <div>Loading...</div>
          </div>
        </div>
      ) : (
        <div className={styles.chatApp}>
          <WebsiteHeader getToken={getToken} remainingDailyFreeMessages={remainingDailyFreeMessages} totalDailyFreeMessages={totalDailyFreeMessages} />

          {showFirstMessageToast && (
            <div className={styles.proTipToastNotification}>
              <div><b>Pro Tip:</b> Log in to save recipes to your cookbook!</div>
            </div>
          )}

          {!hasSubmitted && (
            <div className={styles.initialPrompt}>
              <h1>What would you like to cook today?</h1>
            </div>
          )}

          {hasSubmitted && (
            <ChatHistory messages={messages} isTyping={isTyping} getToken={getToken} isAuthenticated={isAuthenticated} />
          )}

          <ChatInput
            handleSend={handleSend}
            className={hasSubmitted ? "" : styles.initialChatInput}
          />

          {!hasSubmitted && (
            <div className={styles.promptButtons}>
              <button onClick={() => handleSend('🥪 A ham sandwich')}>🥪 A ham sandwich</button>
              <button onClick={() => handleSend('🍗 Tandoori chicken')}>🍗 Tandoori chicken</button>
              <button onClick={() => handleSend('🥗 A kale salad')}>🥗 A kale salad</button>
              <button onClick={() => handleSend('🍣 An Asian-style salmon')}>🍣 An Asian-style salmon</button>
            </div>
          )}

        </div>
      )}
    </>
  );
};

export default ChatApp;